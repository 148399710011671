<template>
	<Row class="trading-guides-seciton">
		<Container class="lg:flex">
			<div class="hidden lg:block w-full max-w-[320px] relative">
				<img
					src="/images/Tim-Sykes-Headshot.webp"
					width="500"
					height="634"
					class="tim-shot"
					alt="Tim-Sykes-Headshot"
				/>
			</div>

			<div class="w-full">
				<Heading
					tag="h2"
					type="big"
					class="text-white mb-[24px]"
					text="Start Learning Now with My FREE Trading Guides"
				/>

				<img
					src="/images/Tim-Sykes-Headshot.webp"
					width="386"
					height="490"
					class="md:mx-auto lg:hidden"
					alt="Tim-Sykes-Headshot"
				/>

				<div class="list-container">
					<div class="list-heading block w-full">
						<h3 class="block">Trading 101</h3>
					</div>

					<div class="list-container-body">
						<div class="list-container-left">
							<div class="flex flex-col">
								<button
									v-for="tab in tabs"
									:key="tab.id"
									label="tab button"
									class="font-semibold text-left text-base mb-0"
									:class="{ active: activeTab === tab.id }"
									:data-tab-link="tab.id"
									@click="activateTab(tab.id)"
								>
									{{ tab.label }}
								</button>
							</div>
						</div>

						<div class="list-container-children">
							<div class="list-item-with-arrow-container">
								<ListItemWithArrow
									v-for="(item, k) in tabData[activeTab - 1]"
									:key="k + 'tab-item'"
									:url="item.url"
									:title="item.label"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	</Row>
</template>

<script setup>
import { ref } from "vue";

const activeTab = ref(1);

const tabs = [
	{ id: 1, label: "Top Trending Stocks" },
	{ id: 2, label: "How To's" },
	{ id: 3, label: "Trading Strategies" },
	{ id: 4, label: "Beginners' Guides" },
	{ id: 5, label: "Best Brokers and Apps" }
];

const activateTab = (tabId) => {
	activeTab.value = tabId;
};
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.list-heading {
	background-color: $neutral-100;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0;

	h3 {
		font-size: 24px;
		color: $primary-900;
		font-weight: 700;

		@include breakpoint(md) {
			font-size: 32px;
		}
	}
}

.list-container {
	background-color: white;
	overflow: hidden;
	border-radius: 16px;

	@include breakpoint(lg) {
		flex-wrap: wrap;
		display: flex;
	}
}
.list-container-body {
	width: 100%;
	@include breakpoint(lg) {
		display: flex;
	}
}

.list-container-children {
	padding: 24px 16px;
	width: 100%;

	@include breakpoint(lg) {
		padding: 40px 32px;
	}
}

.list-item-with-arrow-container {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.list-container-left {
	display: inline-block;
	background-color: $neutral-200;
	padding: 0;
	width: 100%;
	min-width: 260px;

	@include breakpoint(lg) {
		width: auto;
		padding: 24px 0;
	}
	button {
		padding: 16px 20px;
		font-size: 16px;
		border-bottom: 1px solid $neutral-500;
		&:hover {
			background-color: $primary-300;
			border-bottom-color: $primary-300;
		}

		&.active {
			color: $primary-50;
			background-color: $primary-700;
			border-bottom: 1px solid $primary-700;
		}
	}
}

.trading-guides-seciton {
	background-color: $primary-500;
	overflow: hidden;
}

.tim-shot {
	@include breakpoint(lg) {
		display: flex;
		position: absolute;
		min-width: 420px;
		right: -56px;
		bottom: -80px;
	}
	@include breakpoint(xl) {
		display: flex;
		position: absolute;
		min-width: 500px;
		right: -36px;
		bottom: -88px;
	}
}
</style>
