<template>
	<Row>
		<Container>
			<div class="lg:hidden mb-6">
				<img
					src="/images/ts-news-banner.png"
					width="600"
					height="600"
					loading="lazy"
					alt="trading-challenge-image"
					class="block mt-[32px] xl:mt-0 mx-auto xl:ml-auto rounded-xl"
				/>
			</div>
			<Heading
				tag="h2"
				class="border-l-[8px] pl-[16px] border-primary-500 lg:border-none lg:text-center mx-auto mb-0 lg:mb-9 lg:px-[124px] inline-block leading-none font-bold"
				text="Join me as I break down the day's hottest stocks and most crucial breaking news"
			/>

			<div class="xl:grid lg:grid-cols-2 gap-6 lg:gap-12 mb-6">
				<div class="mb-[32px] lg:mb-0">
					<div class="link-block mb-6">
						<NuxtLink
							v-for="item in news"
							:key="item[1].slug"
							:to="item[0]"
							class="flex items-start pt-4 hover:text-primary-500"
						>
							<span class="h-9 w-9 flex items-center justify-center mr-3">
								<svg
									class="h-6 w-6"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
										fill="currentColor"
										fill-rule="evenodd"
										clip-rule="evenodd"
									></path>
								</svg>
							</span>
							<span
								class="pb-4 border-b-[1px] border-neutral-900 font-semibold text-lg w-full"
								v-html="item[1].title"
							></span>
						</NuxtLink>
					</div>
				</div>
				<div class="hidden lg:block">
					<img
						src="/images/ts-news-banner.png"
						width="600"
						height="600"
						loading="lazy"
						alt="trading-challenge-image"
						class="block mt-[32px] xl:mt-0 mx-auto xl:ml-auto rounded-xl"
					/>
				</div>
			</div>

			<CtaButton
				to="/news/"
				class="mx-auto lg:mx-0 text-center"
			>
				Read More Breaking News Here!
			</CtaButton>
		</Container>
	</Row>
</template>

<script setup>
const { $postsListDict } = useNuxtApp();

const news = computed(() => {
	const list = Object.entries($postsListDict).filter(
		([_, value]) => value.type === "news"
	);
	return list.slice(0, 5);
});
</script>
