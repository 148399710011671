<template>
	<span class="list-item-with-arrow">
		<NuxtLink
			class="list-item-with-arrow-heading"
			:data-tab-link="url"
			:to="url"
		>
			<span class="bg-white pr-[8px]">
				{{ title }}
			</span>
			<svg
				class="ml-auto"
				width="32"
				height="33"
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16 29.8333C8.63622 29.8333 2.66669 23.8637 2.66669 16.5C2.66669 9.13619 8.63622 3.16666 16 3.16666C23.3638 3.16666 29.3334 9.13619 29.3334 16.5C29.3334 23.8637 23.3638 29.8333 16 29.8333ZM11.6667 13.1666H17.9874L10.9631 20.1228C10.5707 20.5115 10.5676 21.1445 10.9562 21.5369C11.3448 21.9295 11.978 21.9325 12.3704 21.5439L19.3334 14.6484V20.8332C19.3334 21.3855 19.7811 21.8332 20.3334 21.8332C20.8856 21.8332 21.3334 21.3855 21.3334 20.8332V12.1666C21.3334 11.6143 20.8856 11.1666 20.3334 11.1666H11.6667C11.1144 11.1666 10.6667 11.6143 10.6667 12.1666C10.6667 12.7189 11.1144 13.1666 11.6667 13.1666Z"
					fill="#1D4ED8"
				/>
			</svg>
		</NuxtLink>
	</span>
</template>

<script setup>
const { title, url } = defineProps({
	title: {
		type: String,
		required: true,
		default: ""
	},
	url: {
		type: String,
		required: true,
		default: ""
	}
});
</script>

<style lang="scss" scoped>
@import "../assets/utils.scss";

.list-item-with-arrow {
	display: flex;
	align-items: center;
	gap: 8px;
	position: relative;
	width: 100%;
	cursor: pointer;
	&::before {
		content: "";
		height: 1px;
		width: 93%;

		position: absolute;
		bottom: 8px;
		border-bottom: 3px dotted $neutral-200;
	}

	svg {
		position: relative;
		z-index: 1;
		width: 22px;
		height: 22px;
		@include breakpoint(md) {
			width: 26px;
			height: 26px;
		}
	}

	&:hover {
		.list-item-with-arrow-heading {
			color: $primary-500;
			white-space: flex-wrap;
		}
	}
}

.list-item-with-arrow-heading {
	color: $neutral-800;
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	position: relative;
	z-index: 1;
	padding-right: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;

	span {
		font-family: Rubik;
	}
}
</style>
