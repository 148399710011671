<template>
	<Row class="pb-0">
		<Container>
			<div
				id="custom-trustpilot-container"
				class="w-full"
			>
				<div class="w-full max-w-[660px]">
					<Heading
						tag="h2"
						type="big"
						class="border-l-[8px] pl-[16px] border-primary-500 leading-none mb-[24px]"
						text="In the past 10 years, over 16,000 students have taken the Trading Challenge"
					/>
				</div>

				<!-- TrustBox widget - Grid -->
				<div
					class="trustpilot-widget mt-[64px]"
					data-locale="en-US"
					data-template-id="539adbd6dec7e10e686debee"
					data-businessunit-id="5b698af0a68ff100011e6638"
					data-style-height="620px"
					data-style-width="100%"
					data-theme="light"
					data-stars="4,5"
					data-review-languages="en"
				/>
				<!-- End TrustBox widget -->
			</div>
		</Container>
	</Row>
</template>

<script setup>
onMounted(() => {
	if (process.client && window.Trustpilot) {
		window.Trustpilot.loadFromElement(
			document.querySelector(".trustpilot-widget"),
			true
		);
	}
});
</script>

<style lang="scss">
.tp-widget-reviews-filter-label {
	display: none !important;
}

#custom-trustpilot-container {
	padding: 0 0 80px 0 !important;
}
</style>
