export const tabData = [
	[
		{ label: "Penny Stocks to Watch", url: "/blog/penny-stocks-to-watch/" },
		{ label: "Hot Stocks to Buy Now", url: "/blog/hot-stocks/" },
		{
			label: "Stocks with Breaking News",
			url: "/blog/stocks-with-breaking-news/"
		},
		{ label: "Low Float, Big Gainers", url: "/blog/stock-float/" },
		{
			label: "Day Trading Stocks to Buy",
			url: "/blog/best-day-trading-stocks/"
		}
	],
	[
		{
			label: "How to Day Trade < $25k",
			url: "/blog/how-to-day-trade-without-25k/"
		},
		{
			label: "How to Trade for a Living",
			url: "/blog/how-to-make-a-living-day-trading/"
		},
		{
			label: "How to Use Stock Scanners",
			url: "/blog/how-to-use-stock-scanners/"
		},
		{
			label: "How to Short Sell Stocks",
			url: "/blog/how-to-short-sell-stocks/"
		},
		{
			label: "Find Penny Stocks Pre-Spike",
			url: "/blog/my-secret-formula-for-finding-penny-stocks-pre-spike/"
		}
	],
	[
		{ label: "Penny Stocks", url: "/blog/penny-stocks/" },
		{ label: "Day Trading", url: "/blog/day-trading-strategy/" },
		{ label: "Reversal Trading", url: "/blog/reversal-trading-strategy/" },
		{ label: "Gap and Go", url: "/blog/gap-trading-strategy/" },
		{ label: "Bull Flag", url: "/blog/bull-flag-patterns/" }
	],
	[
		{ label: "Day Trading Guide", url: "/blog/day-trading/" },

		{ label: "Options Trading Guide", url: "/blog/options-trading/" },
		{
			label: "Small Account Option Trading",
			url: "/blog/small-account-trading/"
		},
		{ label: "Stock Order Types", url: "/blog/stock-order-types/" },
		{ label: "Guide to Technical Analysis", url: "/blog/technical-analysis/" }
	],
	[
		{ label: "Top Rated Trading Platforms", url: "/blog/day-trading-basics/" },
		{ label: "Best IRA Accounts", url: "/blog/best-roth-ira-accounts/" },
		{
			label: "Options Trading Platforms",
			url: "/blog/best-options-trading-platforms/"
		},
		{ label: "Top Stock Trading Apps", url: "/blog/stock-trading-app/" },
		{
			label: "Best Broker for Day Trading",
			url: "/blog/best-broker-for-day-trading/"
		}
	]
];
